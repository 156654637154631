import validate from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_bsjcnpicesjllxurakqfcnamfu/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45redirect_45global from "/app/src/middleware/01.redirect.global.ts";
import _02_45auth_45global from "/app/src/middleware/02.auth.global.ts";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_bsjcnpicesjllxurakqfcnamfu/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45redirect_45global,
  _02_45auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}