import revive_payload_client_Xrfa2tWIEE from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_bsjcnpicesjllxurakqfcnamfu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_I3UsYbTKBM from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_bsjcnpicesjllxurakqfcnamfu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Ax26qIxztX from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_bsjcnpicesjllxurakqfcnamfu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import sentry_client_2QVk3eqh9G from "/app/node_modules/.pnpm/@binaryoverload+nuxt-sentry@0.4.0_magicast@0.3.5_rollup@4.24.0_vue@3.4.38_typescript@5.5.3_/node_modules/@binaryoverload/nuxt-sentry/dist/runtime/sentry.client.mjs";
import payload_client_nkXF8Q2D2u from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_bsjcnpicesjllxurakqfcnamfu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_knaUxdqvv6 from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_bsjcnpicesjllxurakqfcnamfu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_g8J7UG1WiL from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_bsjcnpicesjllxurakqfcnamfu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_J7B7u2JjHV from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_bsjcnpicesjllxurakqfcnamfu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Q2zRYM5wxM from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.2_magicast@0.3.5_rollup@4.24.0_typescript@5.5.3_vue@3.4.38_typescript@5.5.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_VuyU23NwcP from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_bsjcnpicesjllxurakqfcnamfu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_dQI3VEPSbH from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_magicast@0.3.5_rollup@4.24.0_vue@3.4.38_typescript@5.5.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_BZdZVulZXF from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_magicast@0.3.5_rollup@4.24.0_vue@3.4.38_typescript@5.5.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_tqXpJwXBjI from "/app/node_modules/.pnpm/@nuxt+icon@1.5.5_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_sass@1.79.4_terse_k5jjupztnjddsff3oc6lxjetcm/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_vRsCUcFSkf from "/app/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.2_magicast@0.3.5_rollup@4.24.0_typesc_h77bivqxh4zvvx2gzhcagdrusm/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import chart_client_itH0xDmBxD from "/app/src/plugins/chart.client.ts";
import event_bus_2B8uO4OVrn from "/app/src/plugins/event-bus.ts";
import nanoid_XOie5CrMEc from "/app/src/plugins/nanoid.ts";
import ofetch_adqO16FtiU from "/app/src/plugins/ofetch.ts";
import popper_uNmHSnnDKH from "/app/src/plugins/popper.ts";
import short_uuid_LeqKE96ZEI from "/app/src/plugins/short-uuid.ts";
import tanstack_query_AjE6EeU5nP from "/app/src/plugins/tanstack-query.ts";
export default [
  revive_payload_client_Xrfa2tWIEE,
  unhead_I3UsYbTKBM,
  router_Ax26qIxztX,
  sentry_client_2QVk3eqh9G,
  payload_client_nkXF8Q2D2u,
  navigation_repaint_client_knaUxdqvv6,
  check_outdated_build_client_g8J7UG1WiL,
  chunk_reload_client_J7B7u2JjHV,
  plugin_vue3_Q2zRYM5wxM,
  components_plugin_KR1HBZs4kY,
  prefetch_client_VuyU23NwcP,
  switch_locale_path_ssr_dQI3VEPSbH,
  i18n_BZdZVulZXF,
  plugin_tqXpJwXBjI,
  plugin_vRsCUcFSkf,
  chart_client_itH0xDmBxD,
  event_bus_2B8uO4OVrn,
  nanoid_XOie5CrMEc,
  ofetch_adqO16FtiU,
  popper_uNmHSnnDKH,
  short_uuid_LeqKE96ZEI,
  tanstack_query_AjE6EeU5nP
]