import type { skipToken } from "@tanstack/vue-query"
import type { FetchOptions, FetchResponse } from "ofetch"
import type { SuccessResponse } from "~/types/apiResponses"

import {
  type DefaultError,
  type QueryClient,
  type QueryFunction,
  type QueryFunctionContext,
  type QueryKey,
  type UseQueryReturnType,
  useQuery,
} from "@tanstack/vue-query"
import { defu } from "defu"

import { useAuthStore } from "~/stores/auth"
import { nullToUndefined } from "~/utils/helpers"

export function getBaseURL() {
  const config = useRuntimeConfig()

  return import.meta.client
    ? config.public.http?.browserBaseURL
    : config.http?.baseURL
}

export function addClientInfo(
  headers: HeadersInit | undefined,
): HeadersInit | undefined {
  if (import.meta.client) return headers

  const { jwtSigningKey } = useRuntimeConfig()
  const { $jwt } = useNuxtApp()

  const clientProperties = {
    userAgent: getRequestUserAgent(),
    ipAddress: getRequestIP(),
  }

  const clientJwt = $jwt.sign(clientProperties, jwtSigningKey, {
    expiresIn: "1m", // Give a long enough expiry for timeouts to not be critical, but short enough that re-use of a captured token would be limited
    issuer: "client-props.deimos-frontend",
  })

  if (Array.isArray(headers)) {
    return [...headers, ["x-client-properties", clientJwt]]
  }

  return {
    ...headers,
    "x-client-properties": clientJwt,
  }
}

// We need to define these types here because the types from @tanstack/vue-query are not exported
type QueryOptions<
  TQueryFnData,
  TError,
  TData,
  TQueryKey extends QueryKey,
> = Exclude<
  Parameters<typeof useQuery<TQueryFnData, TError, TData, TQueryKey>>[0],
  globalThis.Ref
>

type QueryFunctionOrSkipToken<TQueryFnData, TQueryKey extends QueryKey> =
  | QueryFunction<TQueryFnData, TQueryKey>
  | typeof skipToken

/**
 * Wraps a query function with error handling.
 *
 * @template TQueryFnData - The type of the data returned by the query function.
 * @template TQueryKey - The type of the query key.
 * @param {QueryFunctionOrSkipToken<TQueryFnData, TQueryKey> | undefined} queryFn - The query function to wrap.
 * @returns {QueryFunctionOrSkipToken<TQueryFnData, TQueryKey> | undefined} - The wrapped query function.
 */
function wrapQueryFnInternal<
  TQueryFnData = unknown,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryFn: QueryFunctionOrSkipToken<TQueryFnData, TQueryKey> | undefined,
): QueryFunctionOrSkipToken<TQueryFnData, TQueryKey> | undefined {
  if (typeof queryFn !== "function") return queryFn

  return async (context: QueryFunctionContext<TQueryKey>) => {
    try {
      return await queryFn(context)
    } catch (e) {
      processApiError(e)
      throw e
    }
  }
}

/**
 * Wraps a query function with error handling. Handles both refs and non-refs.
 *
 * @param {QueryOptions<TQueryFnData, TError, TData, TQueryKey>["queryFn"]} queryFn - The query function to wrap.
 * @returns {QueryOptions<TQueryFnData, TError, TData, TQueryKey>["queryFn"]} - The wrapped query function.
 */
function wrapQueryFn<
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryFn: QueryOptions<TQueryFnData, TError, TData, TQueryKey>["queryFn"],
): QueryOptions<TQueryFnData, TError, TData, TQueryKey>["queryFn"] {
  if (typeof queryFn === "undefined") return queryFn
  if (isRef(queryFn)) return computed(() => wrapQueryFnInternal(queryFn.value))
  if (typeof queryFn !== "function") return queryFn

  return wrapQueryFnInternal(queryFn)
}

export async function useDeimosQuery<
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: QueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  queryClient?: QueryClient,
): Promise<UseQueryReturnType<TData, TError>> {
  const returnValue = useDeimosQueryNoSuspense(options, queryClient)

  // Await the suspense function to ensure proper fetching in SSR
  if (returnValue.isLoading.value) {
    await returnValue.suspense()
  }

  return returnValue
}

export function useDeimosQueryNoSuspense<
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: QueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  queryClient?: QueryClient,
): UseQueryReturnType<TData, TError> {
  return useQuery(
    {
      ...options,
      queryFn: wrapQueryFn(options.queryFn),
    },
    queryClient,
  )
}

export async function httpRequest<T = never>(
  request: string,
  method: "get" | "patch" | "put" | "delete" | "post" = "get",
  config?: FetchOptions<"json">,
): Promise<T> {
  const { $ofetch } = useNuxtApp()
  const authStore = useAuthStore()

  const requestConfig = defu<FetchOptions<"json">, FetchOptions<"json">[]>(
    config,
    {
      method,
    },
    authStore.token
      ? {
          headers: {
            Authorization: `Bearer ${authStore.token}`,
          },
        }
      : {},
  )

  requestConfig.headers = addClientInfo(requestConfig.headers)

  const response = await $ofetch<SuccessResponse<T>>(request, requestConfig)
  return nullToUndefined(response?.data) as T
}

export async function httpRequestRaw(
  request: string,
  method: "get" | "patch" | "put" | "delete" | "post",
  config?: FetchOptions,
): Promise<FetchResponse<any>> {
  const { $ofetch } = useNuxtApp()
  const authStore = useAuthStore()

  const requestConfig = defu<FetchOptions, FetchOptions[]>(
    config,
    {
      method,
    },
    authStore.token
      ? {
          headers: {
            Authorization: `Bearer ${authStore.token}`,
          },
        }
      : {},
  )

  requestConfig.headers = addClientInfo(requestConfig.headers)

  return await $ofetch.raw(request, requestConfig)
}
